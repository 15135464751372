import { render, staticRenderFns } from "./hu-si-tai.vue?vue&type=template&id=4a351386&scoped=true"
import script from "./hu-si-tai.vue?vue&type=script&lang=js"
export * from "./hu-si-tai.vue?vue&type=script&lang=js"
import style0 from "./hu-si-tai.vue?vue&type=style&index=0&id=4a351386&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a351386",
  null
  
)

export default component.exports