// 巴林右旗 所属乡镇
import DaBan from '@/components/geoJson-maps/blyq-town/da-ban.vue'
import SuBoRiGa from '@/components/geoJson-maps/blyq-town/su-bo-ri-ga.vue'
import XingFuZhiLu from '@/components/geoJson-maps/blyq-town/xing-fu-zhi-lu.vue'
import BaYanHuShuo from '@/components/geoJson-maps/blyq-town/ba-yan-hu-shuo.vue'
import ChaGanMuLun from '@/components/geoJson-maps/blyq-town/cha-gan-mu-lun.vue'
import MeiDianHua from '@/components/geoJson-maps/blyq-town/mei-dian-hua.vue'
import BaYanTaLa from '@/components/geoJson-maps/blyq-town/ba-yan-ta-la.vue'
import BaoRiWuSu from '@/components/geoJson-maps/blyq-town/bao-ri-wu-su.vue'
import XiLaMuLun from '@/components/geoJson-maps/blyq-town/xi-la-mu-lun.vue'
import ChaGanNuoEr from '@/components/geoJson-maps/blyq-town/cha-gan-nuo-er.vue'

// 巴林右旗 索博日嘎镇 所有 嘎查
import TaXi from '@/components/geoJson-maps/so-bo-ri-ga-village/ta-xi.vue';
import SoBoRiGaVillage from '@/components/geoJson-maps/so-bo-ri-ga-village/so-bo-ri-ga-village.vue';
import ChangXing from '@/components/geoJson-maps/so-bo-ri-ga-village/chang-xing.vue';
import WuLanShaoLeng from '@/components/geoJson-maps/so-bo-ri-ga-village/wu-lan-shao-leng.vue';
import BaGenTu from '@/components/geoJson-maps/so-bo-ri-ga-village/ba-gen-tu.vue';
import HuiTongHe from '@/components/geoJson-maps/so-bo-ri-ga-village/hui-tong-he.vue';
import AShanAiLi from '@/components/geoJson-maps/so-bo-ri-ga-village/a-shan-ai-li.vue';
import AShanHe from '@/components/geoJson-maps/so-bo-ri-ga-village/a-shan-he.vue';
import HanShanGou from '@/components/geoJson-maps/so-bo-ri-ga-village/han-shan-gou.vue';
import ChaoYang from '@/components/geoJson-maps/so-bo-ri-ga-village/chao-yang.vue';
import RongSheng from '@/components/geoJson-maps/so-bo-ri-ga-village/rong-sheng.vue';
import HuDuGe from '@/components/geoJson-maps/so-bo-ri-ga-village/hu-du-ge.vue';


// 巴林右旗 巴彦琥硕 所有 嘎查
import ShaRiGaDaBa from '@/components/geoJson-maps/ba-yan-hu-shuo-village/sha-ri-ga-da-ba.vue';
import ShanTou from '@/components/geoJson-maps/ba-yan-hu-shuo-village/shan-tou.vue';
import SiJia from '@/components/geoJson-maps/ba-yan-hu-shuo-village/si-jia.vue';
import ZhongZu from '@/components/geoJson-maps/ba-yan-hu-shuo-village/zhong-zu.vue';

// 巴林右旗 大坂镇 所有 嘎查
import HaoRaoQin from '@/components/geoJson-maps/da-ban-village/hao-rao-qin.vue';
import TaiBuDai from '@/components/geoJson-maps/da-ban-village/tai-bu-dai.vue';
import DaLeng from '@/components/geoJson-maps/da-ban-village/da-leng.vue';
import DaBanHamlet from '@/components/geoJson-maps/da-ban-village/da-ban.vue';
import GuLiGuTai from '@/components/geoJson-maps/da-ban-village/gu-li-gu-tai.vue';
import HongQi from '@/components/geoJson-maps/da-ban-village/hong-qi.vue';
import JiBuTu from '@/components/geoJson-maps/da-ban-village/ji-bu-tu.vue';
import WenGenTu from '@/components/geoJson-maps/da-ban-village/wen-gen-tu.vue';
import XiHa from '@/components/geoJson-maps/da-ban-village/xi-ha.vue';
import ShiJiaZi from '@/components/geoJson-maps/da-ban-village/shi-jia-zi.vue';
import SuAiLi from '@/components/geoJson-maps/da-ban-village/su-ai-li.vue';
import ZhaoHuDuGe from '@/components/geoJson-maps/da-ban-village/zhao-hu-du-ge.vue';
import WengGenMaoDu from '@/components/geoJson-maps/da-ban-village/weng-gen-mao-du.vue';
import TaoBaiCun from '@/components/geoJson-maps/da-ban-village/tao-bai-cun.vue';
import GuiLeSuTai from '@/components/geoJson-maps/da-ban-village/gui-le-su-tai.vue';
import HuSiTai from '@/components/geoJson-maps/da-ban-village/hu-si-tai.vue';

// 巴林右旗 查干沐沦苏木 所有 嘎查
import ShaBaErTai from '@/components/geoJson-maps/cha-gan-mu-lun-village/sha-ba-er-tai.vue';
import TaBenHua from '@/components/geoJson-maps/cha-gan-mu-lun-village/ta-ben-hua.vue';
import HuTeLe from '@/components/geoJson-maps/cha-gan-mu-lun-village/hu-te-le.vue';
import HuLeSiTuBuLang from '@/components/geoJson-maps/cha-gan-mu-lun-village/hu-le-si-tu-bu-lang.vue';
import ChaGanXiRe from '@/components/geoJson-maps/cha-gan-mu-lun-village/cha-gan-xi-re.vue';
import HaoYiRiMaoDu from '@/components/geoJson-maps/cha-gan-mu-lun-village/hao-yi-ri-mao-du.vue';
import MaoDunDunDa from '@/components/geoJson-maps/cha-gan-mu-lun-village/mao-dun-dun-da.vue';
import ZhuLaQin from '@/components/geoJson-maps/cha-gan-mu-lun-village/zhu-la-qin.vue';
import ChaGanMuLunVillage from '@/components/geoJson-maps/cha-gan-mu-lun-village/cha-gan-mu-lun.vue';
import BaYanXiNa from '@/components/geoJson-maps/cha-gan-mu-lun-village/ba-yan-xi-na.vue';
import DaMaJin from '@/components/geoJson-maps/cha-gan-mu-lun-village/da-ma-jin.vue';
import GangGen from '@/components/geoJson-maps/cha-gan-mu-lun-village/gang-gen.vue';
import YaMaTu from '@/components/geoJson-maps/cha-gan-mu-lun-village/ya-ma-tu.vue';




// 暂无地图电子图片
import Def from '@/components/geoJson-maps/def.vue';




export default {
  components: {
    Def,

    // 巴林右旗所有镇
    DaBan, 
    SuBoRiGa, 
    XingFuZhiLu, 
    BaYanHuShuo,
    ChaGanMuLun,
    MeiDianHua,
    BaYanTaLa,
    BaoRiWuSu,
    XiLaMuLun,
    ChaGanNuoEr,

    // 索博日嘎镇所有嘎查
    TaXi,
    SoBoRiGaVillage, 
    ChangXing, 
    WuLanShaoLeng,
    BaGenTu, 
    HuiTongHe,
    AShanAiLi, 
    AShanHe, 
    HanShanGou, 
    ChaoYang,
    RongSheng,
    HuDuGe,

    // 巴林右旗 巴彦琥硕 所有 嘎查
    ShaRiGaDaBa, 
    ShanTou, 
    SiJia, 
    ZhongZu, 
    
    // 大板镇所有嘎查
    HaoRaoQin,
    TaiBuDai,
    DaLeng,
    DaBanHamlet,
    GuLiGuTai,
    HongQi,
    XiHa,
    WenGenTu,
    ShiJiaZi,
    SuAiLi,
    JiBuTu,
    ZhaoHuDuGe,  
    WengGenMaoDu,
    TaoBaiCun,
    GuiLeSuTai,
    HuSiTai,

    // 查干沐沦苏木
    ShaBaErTai,
    TaBenHua,
    HuTeLe,
    HuLeSiTuBuLang,
    ChaGanXiRe, 
    HaoYiRiMaoDu, 
    MaoDunDunDa, 
    ZhuLaQin, 
    ChaGanMuLunVillage, 
    BaYanXiNa, 
    DaMaJin, 
    GangGen, 
    YaMaTu
  }
}