import { render, staticRenderFns } from "./rong-sheng.vue?vue&type=template&id=6ab0d34d&scoped=true"
import script from "./rong-sheng.vue?vue&type=script&lang=js"
export * from "./rong-sheng.vue?vue&type=script&lang=js"
import style0 from "./rong-sheng.vue?vue&type=style&index=0&id=6ab0d34d&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ab0d34d",
  null
  
)

export default component.exports