<template>
  <div class="ba_lin_you_qi_map">
    <v-chart :options="options" autoresize />
  </div>
</template>

<script>
import * as echarts from "echarts";
import '@/assets/js/map/blyq-town/da-ban.js'

let geoCoordMap = {};
let data = [];

let convertData = function (data) {
  let res = [];
  for (let i = 0; i < data.length; i++) {
    let geoCoord = geoCoordMap[data[i].name];
    if (geoCoord) {
      res.push({
        name: data[i].name,
        value: geoCoord.concat(data[i].value),
      });
    }
  }
  return res;
};

let max = 6000, min = 10;
let maxSize4Pin = 100, minSize4Pin = 20;

export default {
  name: "DaBan",
  props: {
    value: Object,
    ispreview: Boolean,
  },
  data() {
    return {
      options: {
        //backgroundColor: '#0F1C3C',
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            if (params.value.length > 1) {
              return params.data.name + "" + params.data.value[2];
            } else {
              return params.name;
            }
          },
        },
        geo: {
          map: "daban",
          show: false,
          roam: false,
          label: {
            emphasis: {
              show: false,
            },
          },
          layoutSize: "80%",
          itemStyle: {
            normal: {
              borderColor: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "#00F6FF",
                  },
                  {
                    offset: 1,
                    color: "#53D9FF",
                  },
                ],
                false
              ),
              borderWidth: 3,
              shadowColor: "rgba(10,76,139,1)",
              shadowOffsetY: 0,
              shadowBlur: 60,
            },
          },
        },
        series: [
          {
            type: "map",
            map: "daban",
            aspectScale: 0.75,
            roam: true,
            zoom: true,
            scaleLimit: {
              //滚轮缩放的极限控制
              min: 0.5, //缩放最小大小
              max: 6, //缩放最大大小
            },
            label: {
              normal: {
                //调整数值
                position: "right",
                show: true,
                color: "#53D9FF",
                fontSize: 14,
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                //地图块颜色
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#073684", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#2B91B7", // 100% 处的颜色
                    },
                  ],
                  colorStops: [
                    {
                      offset: 0,
                      // color: "#073684", // 0% 处的颜色
                      color: 'rgb(65, 155, 239)'
                    },
                    {
                      offset: 1,
                      // color: "#061E3D", // 100% 处的颜色
                      color: 'rgb(12, 112, 243)'
                    },
                  ],
                },
                borderColor: "#215495",
                borderWidth: 1,
              },
              //鼠标放置颜色加深
              emphasis: {
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#073684", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#2B91B7", // 100% 处的颜色
                    },
                  ],
                },
              },
            },
            data: data,
          },
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            rippleEffect: {
              brushType: "stroke",
            },
            showEffectOn: "render",
            itemStyle: {
              normal: {
                //气泡颜色
                color: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(5,80,151,0.2)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(5,80,151,0.8)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,108,255,0.7)",
                    },
                  ],
                  global: false,
                },
              },
            },
            label: {
              normal: {
                show: true,
                color: "#fff",
                fontWeight: "bold",
                position: "inside",
                formatter: function (para) {
                  return "{cnNum|" + para.data.value[2] + "}";
                },
                rich: {
                  cnNum: {
                    fontSize: 13,
                    color: "#D4EEFF",
                  },
                },
              },
            },
            symbol: "circle",
            symbolSize: function (val) {
              if (val[2] == 0) {
                return 0;
              }
              return (
                ((maxSize4Pin - minSize4Pin) / (max - min)) * val[2] +
                (maxSize4Pin -
                  ((maxSize4Pin - minSize4Pin) / (max - min)) * max) *
                  1.2
              );
            },
            data: convertData(data),
            zlevel: 1,
          },
        ],
      },
    };
  },
};
</script>


<style>
  .ba_lin_you_qi_map {
    width: 100%;
    height: 100%;
  }

  .echartMap {
    width: 100%;
    height: 100%;
    color: #d4eeff;
  }

  .echarts {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
</style>