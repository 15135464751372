import { render, staticRenderFns } from "./tai-bu-dai.vue?vue&type=template&id=1c844ee1&scoped=true"
import script from "./tai-bu-dai.vue?vue&type=script&lang=js"
export * from "./tai-bu-dai.vue?vue&type=script&lang=js"
import style0 from "./tai-bu-dai.vue?vue&type=style&index=0&id=1c844ee1&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1c844ee1",
  null
  
)

export default component.exports