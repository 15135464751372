import { render, staticRenderFns } from "./weng-gen-mao-du.vue?vue&type=template&id=0134a761&scoped=true"
import script from "./weng-gen-mao-du.vue?vue&type=script&lang=js"
export * from "./weng-gen-mao-du.vue?vue&type=script&lang=js"
import style0 from "./weng-gen-mao-du.vue?vue&type=style&index=0&id=0134a761&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0134a761",
  null
  
)

export default component.exports