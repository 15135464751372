<template>
  <div class="ba_lin_you_qi_map">
    <v-chart :options="options" autoresize />
  </div>
</template>

<script>
import * as echarts from "echarts";
import '@/assets/js/map/ba-yan-hu-shuo-village/sha-ri-ga-da-ba.js'

let geoCoordMap = {};

let data = [];

let convertData = function (data) {
  let res = [];
  for (let i = 0; i < data.length; i++) {
    let geoCoord = geoCoordMap[data[i].name];
    if (geoCoord) {
      res.push({
        name: data[i].name,
        value: geoCoord.concat(data[i].value),
      });
    }
  }
  return res;
};

let max = 6000,
  min = 10;
let maxSize4Pin = 100,
  minSize4Pin = 20;

export default {
  name: "",
  props: {
    value: Object,
    ispreview: Boolean,
  },
  data() {
    return {
      map: null,
      mapName: '',
      options: {
        //backgroundColor: '#0F1C3C',
        tooltip: {
          trigger: "item",
          formatter: function (params) {
            if (params.value.length > 1) {
              return params.data.name + "" + params.data.value[2];
            } else {
              return params.name;
            }
          },
        },
        geo: {
          map: "sharigadaba",
          show: true,
          roam: false,
          label: {
            emphasis: {
              show: false,
            },
          },
          layoutSize: "80%",
          itemStyle: {
            normal: {
              borderColor: new echarts.graphic.LinearGradient(
                0,
                0,
                0,
                1,
                [
                  {
                    offset: 0,
                    color: "#00F6FF",
                  },
                  {
                    offset: 1,
                    color: "#53D9FF",
                  },
                ],
                false
              ),
              borderWidth: 3,
              shadowColor: "rgba(10,76,139,1)",
              shadowOffsetY: 0,
              shadowBlur: 60,
            },
          },
        },
        series: [
          {
            type: "map",
            map: "sharigadaba",
            aspectScale: 0.75,
            label: {
              normal: {
                //调整数值
                position: "right",
                show: true,
                color: "#53D9FF",
                fontSize: 14,
              },
              emphasis: {
                show: true,
              },
            },
            itemStyle: {
              normal: {
                //地图块颜色
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      // color: "#073684", // 0% 处的颜色
                      color: 'rgb(65, 155, 239)'
                    },
                    {
                      offset: 1,
                      // color: "#061E3D", // 100% 处的颜色
                      color: 'rgb(12, 112, 243)'
                    },
                  ],
                },
                borderColor: "#215495",
                borderWidth: 1,
              },
              //鼠标放置颜色加深
              emphasis: {
                areaColor: {
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "#073684", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#2B91B7", // 100% 处的颜色
                    },
                  ],
                },
              },
            },
            data: data,
          },
          {
            type: "effectScatter",
            coordinateSystem: "geo",
            rippleEffect: {
              brushType: "stroke",
            },
            showEffectOn: "render",
            itemStyle: {
              normal: {
                //气泡颜色
                color: {
                  type: "radial",
                  x: 0.5,
                  y: 0.5,
                  r: 0.5,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(5,80,151,0.2)",
                    },
                    {
                      offset: 0.8,
                      color: "rgba(5,80,151,0.8)",
                    },
                    {
                      offset: 1,
                      color: "rgba(0,108,255,0.7)",
                    },
                  ],
                  global: false,
                },
              },
            },
            label: {
              normal: {
                show: true,
                color: "#fff",
                fontWeight: "bold",
                position: "inside",
                formatter: function (para) {
                  return "{cnNum|" + para.data.value[2] + "}";
                },
                rich: {
                  cnNum: {
                    fontSize: 13,
                    color: "#D4EEFF",
                  },
                },
              },
            },
            symbol: "circle",
            symbolSize: function (val) {
              if (val[2] == 0) {
                return 0;
              }
              return (
                ((maxSize4Pin - minSize4Pin) / (max - min)) * val[2] +
                (maxSize4Pin -
                  ((maxSize4Pin - minSize4Pin) / (max - min)) * max) *
                  1.2
              );
            },
            data: convertData(data),
            zlevel: 1,
          },
        ],
      },
      optionsStyle: {}, // 样式
      optionsData: {}, // 数据
      optionsCollapse: {}, // 图标属性
      optionsSetup: {},
    };
  },
  computed: {
    
  },
 
  mounted() {

  },
  methods: {


  },
};
</script>
<style lang="less" scoped>

.ba_lin_you_qi_map {
  width: 100%;
  height: 100%;
}

.echartMap {
  width: 100%;
  height: 100%;
  color: #d4eeff;
}

.echarts {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.village {
  font-size: 26px;
  color: #fff;
  position: relative;
  top: 70px;
  left: 70px;
  z-index: 9999999;
}
.village:hover {
  cursor: pointer !important;
  color: #2366fc !important;
}

.tong_ji_dialog {
  width: 900px;
  height: 700px;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 51%;
  transform: translate(-50%, -50%);
  z-index: 99999;

  .tong_ji_title {
    width: 100%;
    height: 60px;
    line-height: 60px;
    background-color: #2366fc;
    color: #fff;
    font-size: 20px;
    
  }

  .tong_ji_list {
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
    // border: 1px solid #f00;
    .tong_ji_item {
      padding: 10px;
      border-bottom: 1px solid #eee;
      .tong_ji_name {
        text-align: left;
        padding: 10px;
        font-size: 18px;
        color: #212121;
        cursor: pointer;
        font-weight: bold;
      }

      span { font-size: 18px; margin-right: 20px; }
    }
  }

  .footer_btn {
    margin-top: 20px;
  }
}

// 询问跳转 dialog
.xun_wen_dialog {
  width: 100%;
  height: 100%;
  background-color: rgba(#212121, .8);
  position: relative;
  top: 0;
  left: 0;

  .xun_wen {
    width: 50%;
    height: 300px;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99999;

    .title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #2366fc;
      color: #fff;
      font-size: 20px;
    }

    .footer_btn {
      margin-top: 20px;
    }
  }
}

.mo-mask {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.el-dialog__wrapper {
  background-color: rgba(#212121, .6);

  .el-dialog {
    margin-top: 47% !important;
  }
}
.el-dialog {  margin-top: 47% !important; }
</style>
