import { render, staticRenderFns } from "./shi-jia-zi.vue?vue&type=template&id=4ce9e94b&scoped=true"
import script from "./shi-jia-zi.vue?vue&type=script&lang=js"
export * from "./shi-jia-zi.vue?vue&type=script&lang=js"
import style0 from "./shi-jia-zi.vue?vue&type=style&index=0&id=4ce9e94b&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ce9e94b",
  null
  
)

export default component.exports