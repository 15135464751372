<template>
  <div class="full relative">
    <div class="absolute_center color_fff">暂无地区图片</div>
  </div>
</template>

<script>

export default {
  name: "",

  data() {
    return {
      
    };
  },

  mounted() {
    
  },

  methods: {
    
  },
};
</script>

<style>
</style>
