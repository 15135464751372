import { render, staticRenderFns } from "./sha-ba-er-tai.vue?vue&type=template&id=547ff300&scoped=true"
import script from "./sha-ba-er-tai.vue?vue&type=script&lang=js"
export * from "./sha-ba-er-tai.vue?vue&type=script&lang=js"
import style0 from "./sha-ba-er-tai.vue?vue&type=style&index=0&id=547ff300&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "547ff300",
  null
  
)

export default component.exports